















import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
  name: 'Talk',
  props: ['talk'],
  computed: {
    start: function () {
      return moment(this.talk.fromTime).format('HH:mm');
    },
    end: function () {
      return moment(this.talk.toTime).format('HH:mm');
    }
  }
});
